.warn {
  display: block;
  color: red;
  cursor: pointer;
  color: darken(red, 30);
}

.picture {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border: solid 2px black;
}

.imgCtn {
  display: inline-block;
  position: relative;
  height: 110px;
  width: 110px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.deleteBtn {
  position: absolute;
  top: 5px;
  right: 15px;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: darken(#fff, 30);
  }
}

.container {
  margin: 20px 0px;
}
