.container {
  padding: 2rem;

  .formTitle {
    margin-top: 3rem;
  }

  .link {
    margin-top: 15px;
  }
}
