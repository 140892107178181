.editor {
  :global {
    .ql-snow {
      .ql-picker-label:before {
        position: absolute;
      }

      .ql-tooltip {
        position: static;

        &.ql-editing a.ql-action:after {
          content: 'Enregistrer';
        }

        &:before {
          content: "Visiter l'URL:";
        }

        &[data-mode=link]:before {
          content: "Saisir l'URL:";
        }

        a {
          line-height: inherit;

          &.ql-action:after {
            content: 'Modifier';
          }

          &.ql-remove:before {
            content: 'Supprimer';
          }
        }
      }

      .ql-picker {
        .ql-header {
          .ql-picker-label[data-value="1"]:before,
          .ql-picker-item[data-value="1"]:before {
            content: 'Titre 1';
          }

          .ql-picker-label[data-value="2"]:before,
          .ql-picker-item[data-value="2"]:before {
            content: 'Titre 2';
          }

          .ql-picker-label[data-value="3"]:before,
          .ql-picker-item[data-value="3"]:before {
            content: 'Titre 3';
          }

          .ql-picker-label[data-value="4"]:before,
          .ql-picker-item[data-value="4"]:before {
            content: 'Titre 4';
          }

          .ql-picker-label[data-value="5"]:before,
          .ql-picker-item[data-value="5"]:before {
            content: 'Titre 5';
          }

          .ql-picker-label[data-value="6"]:before,
          .ql-picker-item[data-value="6"]:before {
            content: 'Titre 6';
          }
        }
      }
    }
  }
}

