.layout {
  height: 100%;

  .logo {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 70%;
    }
  }
}
