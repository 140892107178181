.container {
  margin: 10px;

  div {
    margin-bottom: 5px;
  }
}

.filterRowSelect {
  margin-bottom: 10px !important;
  width: 338px;
}
