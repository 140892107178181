.container {
  display: flex;

  .imageContainer {
    position: relative;
    height: 100px;
    width: 100px;
    margin-right: 50px;

    .picture {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: solid 2px black;
    }

    .deleteButton {
      display: flex;
      position: absolute;
      top: 2px;
      right: -18px;
      cursor: pointer;

      &:hover {
        color: #000;
      }
    }
  }
}
