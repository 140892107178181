form.form {
  background: #fff;
  box-shadow: 0 0.25rem 1.5rem 0 rgba(0, 0, 0, 0.2);
  padding: 2rem;
  grid-area: 2 / 2 / 2 / 2;
  display: flex;
  flex-direction: column;
  .submit {
    margin-top: 5px;
    margin-left: auto;
  }

  .actionCtn {
    flex-direction: row;
    display: flex;
  }
}
